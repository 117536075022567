import { useTrackStorefrontInteraction } from '@hooks/useTrackStorefrontInteraction';
import { compose } from '@xo-union/react-css-modules';
import { IconButton } from '@xo-union/tk-component-icons';
import {
	CarouselWithStaticVisibleSlots,
	NavigationButtonProps,
} from '@xo-union/ui-carousel';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { MouseEventHandler, useCallback } from 'react';
import { packagesAtom } from '../../jotai/storefront/detailed-pricing';
import { Package } from './Package';
import Styles from './packages.scss';

const PrevButton = ({
	className,
	onClick,
	...props
}: NavigationButtonProps) => {
	const track = useTrackStorefrontInteraction('Packages');

	const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
		(e) => {
			track('Click left');

			if (onClick) {
				onClick(e);
			}
		},
		[track, onClick],
	);

	return (
		<div className={Styles.iconWrapper} aria-roledescription="previous button">
			<IconButton
				className={classNames(Styles.previousButton, className)}
				name="caret_left"
				size="md"
				// @ts-ignore
				onClick={handleClick}
				{...props}
			/>
		</div>
	);
};

const NextButton = ({
	className,
	onClick,
	...props
}: NavigationButtonProps) => {
	const track = useTrackStorefrontInteraction('Packages');

	const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
		(e) => {
			track('Click right');

			if (onClick) {
				onClick(e);
			}
		},
		[track, onClick],
	);

	return (
		<div className={Styles.iconWrapper} aria-roledescription="next button">
			<IconButton
				className={classNames(Styles.nextButton, className)}
				name="caret_right"
				size="md"
				// @ts-ignore
				onClick={handleClick}
				{...props}
			/>
		</div>
	);
};

const packageKey = (
	title: string,
	index: number,
	screen: 'desktop' | 'mobile',
) => {
	return `${title}-${screen}-${index}`;
};

export const Packages = () => {
	const packages = useAtomValue(packagesAtom);

	if (!packages.length) {
		return null;
	}

	return (
		<>
			<div className={classNames(Styles.hideOnDesktop, Styles.list)}>
				{packages.map((pkg, index) => (
					<Package
						key={packageKey(pkg.title, index, 'mobile')}
						initialCollapse={index !== 0}
						pkg={pkg}
					/>
				))}
			</div>
			<div className={classNames(Styles.hideOnMobile, Styles.carousel)}>
				<CarouselWithStaticVisibleSlots
					classes={compose({ container: Styles.carouselContainer })}
					visibleSlots={3}
					renderNextButton={NextButton}
					renderPrevButton={PrevButton}
				>
					{packages.map((pkg, index) => (
						<Package
							key={packageKey(pkg.title, index, 'desktop')}
							initialCollapse={false}
							pkg={pkg}
						/>
					))}
				</CarouselWithStaticVisibleSlots>
			</div>
		</>
	);
};
