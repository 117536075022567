import Styles from '@components/calendar/Calendar.scss';
import { MonthHeader } from '@components/calendar/MonthHeader';
import { MonthSelector } from '@components/calendar/MonthSelector';
import { Day } from '@components/calendar/day';
import { MonthProps } from '@components/calendar/types';
import { getMonthWeeks } from '@components/calendar/utils';
import classNames from 'classnames';
import React from 'react';
import { useDayPicker, useNavigation } from 'react-day-picker';

export const Month = ({ idx, displayMonth }: MonthProps) => {
	const { fixedWeeks, ISOWeek, locale, weekStartsOn, firstWeekContainsDate } =
		useDayPicker();
	const { displayMonths, previousMonth, nextMonth, goToMonth } =
		useNavigation();

	const weeks = getMonthWeeks(displayMonth, {
		useFixedWeeks: Boolean(fixedWeeks),
		ISOWeek,
		locale,
		weekStartsOn,
		firstWeekContainsDate,
	});

	return (
		<>
			<section
				className={classNames(
					{ [Styles.hidden]: displayMonths.length > 1 && idx === 1 },
					Styles.section,
				)}
			>
				<MonthSelector displayMonth={displayMonth} idx={idx} />
				<MonthHeader />
				<div className={Styles.monthDays}>
					{weeks.map((week) => {
						return week.dates.map((day) => {
							return <Day displayMonth={displayMonth} date={day} />;
						});
					})}
				</div>
			</section>
		</>
	);
};
