import { Highlight } from '@components/highlight/highlight';
import { useFetchAvailability } from '@hooks/use-fetch-availability';
import { useIsVenue } from '@hooks/use-is-venue';
import { useShowAvailability } from '@hooks/use-show-availability';
import { useAppSelector } from '@redux/hooks';
import { Decorated } from '@typings/vendor';
import { useAtomValue } from 'jotai';
import React from 'react';
import { Link } from 'react-scroll';
import {
	detailedPricingStartingCostAtom,
	detailedPricingUsuallySpendAtom,
} from '../../jotai/storefront/detailed-pricing';
import {
	photographyServicesAtom,
	travelAvailabilityAtom,
	videographyServicesAtom,
} from '../../jotai/storefront/organization';
import {
	formattedGuestCapacityAtom,
	startingCostAtom,
} from '../../jotai/storefront/pricing';
import Styles from './highlights-scannability.scss';

const CEREMONY_AREA_AMENITY_ID = 'd46b53a6-932e-4b77-8668-a2298c285a6d';
const RECEPTION_AREA_AMENITY_ID = '2ee9d5c0-4167-4450-9734-643c6bee601a';

export const HighlightsScannability = () => {
	const usuallySpend = useAtomValue(detailedPricingUsuallySpendAtom);
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const isVenue = useIsVenue();
	const shouldShowAvailability = useShowAvailability() || false;
	const availabilityData = useFetchAvailability(
		vendor.id,
		shouldShowAvailability,
	);

	const serviceAreas = vendor.serviceArea;
	const travelAvailability = useAtomValue(travelAvailabilityAtom);
	const videographyServices = useAtomValue(videographyServicesAtom);
	const photographyServices = useAtomValue(photographyServicesAtom);
	const guestCapacity = useAtomValue(formattedGuestCapacityAtom);

	const startingCost = useAtomValue(
		isVenue ? startingCostAtom : detailedPricingStartingCostAtom,
	);

	const hasCeremonyAndReceptionArea =
		vendor.amenities.filter((amenity: Settings.VenueAmenity) => {
			if (
				amenity.id === CEREMONY_AREA_AMENITY_ID ||
				amenity.id === RECEPTION_AREA_AMENITY_ID
			) {
				return amenity;
			}
		}).length === 2;

	const currentYear = new Date().getFullYear();

	const highlightClasses = {
		title: Styles.highlightsTitle,
		text: Styles.highlightsText,
	};

	const hasHighlights =
		startingCost ||
		serviceAreas ||
		guestCapacity ||
		hasCeremonyAndReceptionArea ||
		videographyServices ||
		photographyServices ||
		(shouldShowAvailability && availabilityData);

	if (hasHighlights) {
		return (
			<div className={Styles.container}>
				{startingCost && (
					<Highlight
						iconName="budgeter"
						classes={highlightClasses}
						highlightTitle={`${startingCost} starting price`}
						highlightText={
							<span>
								<Link
									href={'#navPricing'}
									to="navPricing"
									smooth
									spy
									isDynamic
									offset={-49}
								>
									See details
								</Link>
							</span>
						}
					/>
				)}
				{serviceAreas && (
					<Highlight
						iconName="address"
						classes={highlightClasses}
						highlightTitle={serviceAreas}
						highlightText={
							travelAvailability ? (
								<span>
									{`Travels up to ${travelAvailability} miles `}
									<Link
										href={'#location'}
										to="location"
										smooth
										spy
										isDynamic
										offset={-49}
									>
										See details
									</Link>
								</span>
							) : (
								''
							)
						}
					/>
				)}
				{guestCapacity && (
					<Highlight
						iconName="guest_couple"
						classes={highlightClasses}
						highlightTitle={guestCapacity}
					/>
				)}

				{hasCeremonyAndReceptionArea && (
					<Highlight
						iconName="category-cer"
						classes={highlightClasses}
						highlightTitle="Holds ceremonies and receptions"
					/>
				)}
				{videographyServices && (
					<Highlight
						iconName="category-vid"
						classes={highlightClasses}
						highlightTitle="Videography services available"
					/>
				)}
				{photographyServices && (
					<Highlight
						iconName="category-wph"
						classes={highlightClasses}
						highlightTitle="Photography services available"
					/>
				)}
				{shouldShowAvailability && availabilityData && (
					<Highlight
						iconName="date"
						classes={highlightClasses}
						highlightTitle={`Booking in ${currentYear}, ${currentYear + 1}`}
						highlightText={
							<Link
								href={'#navAvailability'}
								to="navAvailability"
								smooth
								spy
								isDynamic
								offset={-49}
							>
								See available dates
							</Link>
						}
					/>
				)}
			</div>
		);
	}

	return null;
};
