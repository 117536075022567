import Icon from '@xo-union/tk-component-icons';
import { Body1, Editorial } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { ComponentProps, ReactNode } from 'react';
import Styles from './styles.scss';

interface ClassNameProps {
	container?: string;
	icon?: string;
	title?: string;
	text?: string;
}

interface Props {
	iconName: ComponentProps<typeof Icon>['name'];
	highlightTitle: string | ReactNode;
	highlightText?: string | ReactNode;
	classes?: ClassNameProps;
}

export const Highlight = ({
	iconName,
	highlightTitle,
	highlightText,
	classes = {},
}: Props) => {
	const { container = '', icon = '', title = '', text = '' } = classes;

	return (
		<div className={classNames(Styles.container, container)}>
			<Icon
				name={iconName}
				className={classNames(Styles.icon, icon)}
				size="md"
				data-testid={`highlight-icon-${iconName}`}
			/>
			<div className={Styles.subContainer}>
				<Editorial bold className={classNames(title)}>
					{highlightTitle}
				</Editorial>
				{highlightText ? (
					<Body1 className={classNames(Styles.body, text)}>
						{highlightText}
					</Body1>
				) : null}
			</div>
		</div>
	);
};
