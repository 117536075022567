import {
	desktopSize,
	mobileSize,
} from '@components/filmstrip-carousel/constants';
import Styles from '@components/filmstrip-carousel/styles.scss';
import { PhotoPictureWrapper } from '@components/photo-picture-wrapper';
import classNames from 'classnames';
import React from 'react';
import { Gallery } from 'types/reviews';

export interface FilmstripPhotoProps {
	item: Gallery;
}

export const FilmstripPhoto = ({ item }: FilmstripPhotoProps) => {
	return (
		<PhotoPictureWrapper
			id={item.sourceId}
			containerClassName={Styles.mediaSize}
			imgProps={{
				className: classNames(Styles.borderRadius),
			}}
			lazy
			smartCrop
			width={mobileSize}
			height={mobileSize}
			xs={{ width: mobileSize, height: mobileSize }}
			md={{ width: desktopSize, height: desktopSize }}
		/>
	);
};
