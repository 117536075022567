import noop from '@utils/noop';
import React, { FC, useContext } from 'react';
import { AvailabilityResponse } from './types';

interface CalendarContextValues {
	onDayClick: (
		day: Date,
		dayStatus: 'Unavailable' | 'Available' | 'Partially Available',
	) => void;
	onNextClick: () => void;
	onPrevClick: () => void;
	unavailableDays: Array<string>;
	partiallyAvailableDays: Array<string>;
}

const CalendarContext = React.createContext<CalendarContextValues | undefined>(
	undefined,
);

export const CalendarProvider: FC<
	Partial<CalendarContextValues> & { availabilityData: AvailabilityResponse }
> = ({ children, ...props }) => {
	const getUnavailableDays = () => {
		// Sending unavailable days: Any date not included in the response, but within the requested date range SHOULD BE assumed available.
		const unavailableDays: string[] = [];
		for (const [key, value] of Object.entries(
			props.availabilityData.availability,
		)) {
			if (!value.isAvailable) {
				unavailableDays.push(key);
			}
		}
		return unavailableDays;
	};

	const getPartiallyAvailableDays = () => {
		const partiallyAvailableDays: string[] = [];
		for (const [key, value] of Object.entries(
			props.availabilityData.availability,
		)) {
			if (value.isAvailable && value.events > 0) {
				partiallyAvailableDays.push(key);
			}
		}
		return partiallyAvailableDays;
	};

	const value: CalendarContextValues = {
		onDayClick: props.onDayClick ?? noop,
		onNextClick: props.onNextClick ?? noop,
		onPrevClick: props.onPrevClick ?? noop,
		unavailableDays: getUnavailableDays(),
		partiallyAvailableDays: getPartiallyAvailableDays(),
	};

	return (
		<CalendarContext.Provider value={value}>
			{children}
		</CalendarContext.Provider>
	);
};

export const useCalenderContext = () => {
	const context = useContext(CalendarContext);

	if (context === undefined) {
		throw new Error('useCalenderContext must be within CalendarProvider');
	}

	return context;
};
