import {
	NextButton,
	PrevButton,
} from '@components/carousel-buttons/carousel-buttons';
import { FilmstripPhoto } from '@components/filmstrip-carousel/components/filmstrip-photo';
import { useSlideWidth } from '@components/filmstrip-carousel/hooks/use-slide-width';
import useTrackUnifiedLightbox from '@components/unified-lightbox/hooks/use-track-unified-lightbox';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useUgcStorefrontAnalytics } from '@containers/review-photos/hooks/use-ugc-storefront-analytics';
import { isStorefrontLightboxRevampSelector } from '@redux/experiments/selectors/storefront-lightbox-revamp';
import { useAppSelector } from '@redux/hooks';
import { noopParameters } from '@utils/noop';
import { compose } from '@xo-union/react-css-modules';
import { Carousel } from '@xo-union/ui-carousel';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Gallery } from 'types/reviews';
import Styles from './styles.scss';

interface Props {
	galleryItems: Gallery[];
	onClick?: (index: number, modalType: 'overview' | 'details') => void;
	maxPhotos?: number;
}

export const FilmstripCarousel = ({
	galleryItems,
	onClick = noopParameters,
	maxPhotos = 8,
}: Props) => {
	const isInLightboxRevampExperiment = useAppSelector(
		isStorefrontLightboxRevampSelector,
	);
	const slideWidth = useSlideWidth();
	const [carouselIndex, setCarouselIndex] = useState(0);
	const galleryItemsToDisplay = useMemo(
		() => galleryItems.slice(0, maxPhotos),
		[galleryItems],
	);
	const numberOfExtraPhotos = useMemo(() => {
		return galleryItems.length - maxPhotos;
	}, [galleryItems, maxPhotos]);
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as Vendor.Decorated,
	);
	const trackUgcEvent = useUgcStorefrontAnalytics();
	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);
	const onNavigationIndexChange = (nextIndex: number) => {
		const action = nextIndex > carouselIndex ? 'nav right' : 'nav left';
		trackUgcEvent(action, 'review gallery filmstrip');
		setCarouselIndex(nextIndex);
	};
	const onClickTile = (index: number) => {
		trackUgcEvent('view photo', 'review gallery filmstrip', index + 1);
		onClick(index, 'details');
	};
	const onClickSeeAllTile = () => {
		trackUgcEvent('see all tile', 'review gallery filmstrip');
		isInLightboxRevampExperiment
			? onClick(0, 'overview')
			: onClick(8, 'details');
	};

	const carouselStyles = compose({
		container: Styles.container,
		relativeContainer:
			galleryItemsToDisplay.length < 6
				? Styles.lessThanSix
				: Styles.relativeContainer,
	});
	return (
		<Carousel
			classes={carouselStyles}
			gutter={8}
			minSlideWidth={slideWidth}
			maxSlideWidth={slideWidth}
			renderNextButton={NextButton}
			renderPrevButton={PrevButton}
			onNavigationIndexChange={onNavigationIndexChange}
			navigationIndex={carouselIndex}
		>
			{galleryItemsToDisplay.map((item, index) => (
				<button
					aria-label="review photo"
					type="button"
					key={`filmstrip_carousel_${item.id}`}
					data-testid={`filmstrip_carousel_${item.id}`}
					className={classNames(Styles.mediaWrapper, Styles.mediaSize)}
					onClick={() => onClickTile(index)}
				>
					<FilmstripPhoto item={item} />
				</button>
			))}
			{numberOfExtraPhotos > 0 ? (
				<button
					aria-label="review photo"
					type="button"
					data-testid="filmstrip_carousel_extra_photos"
					className={classNames(Styles.mediaWrapper, Styles.mediaSize)}
					onClick={() => {
						onClickSeeAllTile();
						if (isInLightboxRevampExperiment) {
							trackUnifiedLightbox(
								AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
								AnalyticsConstants.SEE_ALL_IMAGES,
								AnalyticsConstants.portfolioType.REVIEWS,
							);
						}
					}}
				>
					<div
						className={classNames(Styles.overlay, Styles.mediaSize)}
					>{`+${numberOfExtraPhotos}`}</div>
					<FilmstripPhoto item={galleryItems[8]} />
				</button>
			) : null}
		</Carousel>
	);
};
