import '@-test-support/reactRedux/connect';
import { Highlight } from '@components/highlight/highlight';
import { useStorefrontFeatureBvcPlusPricing } from '@feature-flags/hooks/Storefront/useStorefrontFeatureBvcPlusPricing';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useTrackStorefrontInteraction } from '@hooks/useTrackStorefrontInteraction';
import { useAtomValue } from 'jotai/index';
import React, { useCallback } from 'react';
import { Link } from 'react-scroll';
import {
	detailedPricingStartingCostAtom,
	hasStartingCostAtom,
	videographyAvailableAtom,
} from '../../../jotai/storefront/detailed-pricing';
import Styles from './highlights.scss';

export const PhotographerHighlights = () => {
	const hasStartingCost = useAtomValue(hasStartingCostAtom);
	const startingCost = useAtomValue(detailedPricingStartingCostAtom);
	const videographyAvailable = useAtomValue(videographyAvailableAtom);
	const track = useTrackStorefrontInteraction('content highlights');
	const isBvcPlusPricing = useStorefrontFeatureBvcPlusPricing();

	const trackViewDetails = useCallback(() => {
		track('View details');
	}, [track]);
	const isLiteStorefront = useLiteStorefront();

	if (!hasStartingCost && !isBvcPlusPricing) return null;

	return (
		<div className={Styles.highlights}>
			{hasStartingCost && (
				<Highlight
					iconName="budgeter"
					highlightTitle={`${startingCost} starting price`}
					highlightText={
						!isLiteStorefront ? (
							<div className={Styles.priceTextContainer}>
								<Link
									href={'#navPricing'}
									onClick={trackViewDetails}
									to="navPricing"
									smooth
									spy
									isDynamic
									offset={-49}
								>
									See details
								</Link>
							</div>
						) : null
					}
				/>
			)}
			{videographyAvailable && (
				<Highlight
					iconName="category-vid"
					highlightTitle="Videography services available"
				/>
			)}
		</div>
	);
};
