import Styles from '@components/calendar/Calendar.scss';
import { useCalenderContext } from '@components/calendar/CalendarContext';
import { MonthProps } from '@components/calendar/types';
import { compose } from '@xo-union/react-css-modules';
import {
	Option,
	Select,
	SelectChangeEvent,
} from '@xo-union/tk-component-fields';
import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import {
	addYears,
	eachMonthOfInterval,
	eachYearOfInterval,
	format,
	isBefore,
	startOfMonth,
} from 'date-fns';
import React from 'react';
import { useDayPicker, useNavigation } from 'react-day-picker';
import { useTrackAvailabilityCalendar } from './hooks/use-track-availability-calendar';

const IconRight = () => <Icon size="md" name="caret_right" />;
const IconLeft = () => <Icon size="md" name="caret_left" />;

export const MonthSelector = ({ displayMonth, idx }: MonthProps) => {
	const dayPicker = useDayPicker();
	const { previousMonth, nextMonth, goToMonth } = useNavigation();
	const { onPrevClick, onNextClick } = useCalenderContext();
	const trackAvailabilityCalendar = useTrackAvailabilityCalendar();

	const commonDropdownClassNames = {
		input: Styles.dropdownInput,
		caret: Styles.dropdownArrow,
		list: Styles.dropdownList,
	};
	const monthDropdownClassNames = {
		...commonDropdownClassNames,
		container: Styles.monthDropdownContainer,
	};
	const yearDropdownClassNames = {
		...commonDropdownClassNames,
		container: Styles.yearDropdownContainer,
	};

	const monthDropdownClasses = compose(monthDropdownClassNames);
	const yearDropdownClasses = compose(yearDropdownClassNames);

	const handlePreviousClick: React.MouseEventHandler = () => {
		trackAvailabilityCalendar('Arrow left');
		if (!previousMonth) return;
		goToMonth(previousMonth);
		onPrevClick();
	};

	const handleNextClick: React.MouseEventHandler = () => {
		trackAvailabilityCalendar('Arrow right');
		if (!nextMonth) return;
		goToMonth(nextMonth);
		onNextClick();
	};

	const availableMonths = Array.from(
		new Set(
			eachMonthOfInterval({
				start: dayPicker.fromDate || new Date(),
				end: dayPicker.toDate || addYears(new Date(), 1),
			}).map((month: Date) => format(month, 'LLLL')),
		),
	);

	const handleMonthChange = (e: SelectChangeEvent) => {
		trackAvailabilityCalendar('Change month');
		const month = e.target.value;
		const newMonth = new Date(`${month} ${format(displayMonth, 'yyyy')}`);
		goToMonth(
			isBefore(newMonth, startOfMonth(dayPicker.fromDate || displayMonth))
				? addYears(newMonth, 1)
				: newMonth,
		);
	};

	const availableYears = eachYearOfInterval({
		start: dayPicker.fromDate || new Date(),
		end: dayPicker.toDate || addYears(new Date(), 1),
	}).map((year: Date) => format(year, 'yyyy'));

	const handleYearChange = (e: SelectChangeEvent) => {
		trackAvailabilityCalendar('Change year');
		const year = e.target.value;
		const newYear = new Date(`${format(displayMonth, 'LLLL')} ${year}`);
		goToMonth(
			isBefore(newYear, dayPicker.fromDate || displayMonth)
				? dayPicker.fromDate || displayMonth
				: newYear,
		);
	};

	return (
		<div className={Styles.monthSelector}>
			<span className={Styles.monthSelectorArrow}>
				<button
					type="button"
					className={classNames(Styles.button, {
						[Styles.hiddenMd]: idx === 1,
						[Styles.hide]: !previousMonth,
					})}
					onClick={handlePreviousClick}
				>
					<span className={Styles.srOnly}>Previous month</span>
					<IconLeft />
				</button>
			</span>
			<Select
				name=""
				value={format(displayMonth, 'LLLL')}
				classes={monthDropdownClasses}
				onChange={handleMonthChange}
			>
				{availableMonths.map((month) => (
					<Option key={month} value={month}>
						<Body1 bold aria-live="polite" role="presentation">
							{month}
						</Body1>
					</Option>
				))}
			</Select>
			<Select
				name=""
				value={format(displayMonth, 'yyyy')}
				classes={yearDropdownClasses}
				onChange={handleYearChange}
			>
				{availableYears.map((year) => (
					<Option key={year} value={year}>
						<Body1 bold aria-live="polite" role="presentation">
							{year}
						</Body1>
					</Option>
				))}
			</Select>
			<span className={Styles.monthSelectorArrow}>
				<button
					type="button"
					className={classNames(Styles.button, {
						[Styles.hide]: !nextMonth,
					})}
					onClick={handleNextClick}
				>
					<span className={Styles.srOnly}>Next month</span>
					<IconRight />
				</button>
			</span>
		</div>
	);
};
