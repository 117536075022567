import { AboutCta } from '@components/about-cta/about-cta';
import { AboutSingleAward } from '@components/about-single-award/about-single-award';
import { ReadMore } from '@components/read-more/ReadMore';
import { useIsVenue } from '@hooks/use-is-venue';
import { useAppSelector } from '@redux/hooks';
import { Decorated } from '@typings/vendor';
import { Body1, H3, Overline } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { useState } from 'react';
import Social from '../../pages/Storefront/containers/contact/components/social';
import { NavItemProps } from '../../pages/Storefront/containers/nav/types';
import Style from './about-summary.scss';
import { AboutHighlights } from './components/about-highlights/about-highlights';
import { AboutImage } from './components/about-image/about-image';

export const AboutSummary = ({ className }: NavItemProps) => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const [expanded, setExpanded] = useState(false);
	const isVenue = useIsVenue();

	return (
		<div className={className}>
			<div className={Style.header}>
				<Social social={vendor.social} vendor={vendor} />
				<H3 className={Style.title}>
					About this {isVenue ? 'venue' : 'vendor'}
				</H3>
			</div>
			<div className={Style.content}>
				{vendor.bio?.name && (
					<div className={Style.spotlight}>
						<div className={Style.spotlightUser}>
							<AboutImage imageUrl={vendor.bio.photo?.url} />
							<div className={Style.belowImage}>
								<Body1 bold className={Style.name}>
									{vendor.bio.name}
								</Body1>
								<Overline>{vendor.bio.displayRole.toUpperCase()}</Overline>
							</div>
						</div>
						<AboutCta />
					</div>
				)}
				<div className={Style.info}>
					<AboutSingleAward />
					<ReadMore
						clamp
						clampLines={6}
						expanded={expanded}
						setExpanded={setExpanded}
						linkColor="secondary"
						classes={{
							link: classNames(Style.readMoreLink, Style.forceUnderline),
						}}
					>
						<Body1 className={Style.desc}>{vendor?.description}</Body1>
					</ReadMore>
					<AboutHighlights />
				</div>
			</div>
		</div>
	);
};
